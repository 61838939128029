// ParticlesBackground.js
import Particles from 'react-tsparticles';

const ParticlesBackground = () => {
  return (
    <Particles 
      params={{
        background: {
          color: {
            value: "#E8F4F9"  // Set the background color to red
          }
        },
        particles: {
          number: {
            value: 400,
            density: {
              enable: true,
              value_area: 2000
            }
          },
          color: {
            value: "#2C3E50"  // Ensuring particles are black for visibility
          },
          opacity: {
            value: 1,  // Ensure particles are fully opaque
            random: false
          },
          line_linked: {
            enable: true,
            distance: 150,
            color: "#A2D5F2",
            opacity: 0.6,
            width: 1
          },
          move: {
            enable: true,
            speed: 2,  // Reduced speed
            direction: "none",
            random: true,
            straight: false,
            out_mode: "bounce",
            bounce: false,
            attract: {
              enable: false,
              rotateX: 600,
              rotateY: 1200
            }
          }
        },
        interactivity: {
          detect_on: "canvas",
          events: {
            onhover: {
              enable: true,
              mode: "repulse"
            },
            onclick: {
              enable: true,
              mode: "push"
            },
            resize: true
          },
          modes: {
            grab: {
              distance: 400,
              line_linked: {
                opacity: 1
              }
            },
            bubble: {
              distance: 400,
              size: 40,
              duration: 2,
              opacity: 8,
              speed: 3
            },
            repulse: {
              distance: 200,
              duration: 0.4
            },
            push: {
              particles_nb: 4
            },
            remove: {
              particles_nb: 1
            }
          }
        },
        retina_detect: true
      }}
      style={{
        width: '100%',
        height: '100vh',
        position: 'absolute',
        zIndex: 1
      }}
    />
  );
};


export default ParticlesBackground;
