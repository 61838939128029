// stripe.js

const fetchFromFirebase = async (user, field, transformEmail, db, dbref, get) => {
    try {
      const userEmail = transformEmail(user.email);
      const userRef = dbref(db, `gmail_creds/${userEmail}`);
      const snapshot = await get(userRef);
      return snapshot.exists() && snapshot.val()[field];
    } catch (error) {
      console.error("Error fetching from Firebase:", error);
      return false;
    }
  };
  
  export const checkStripeCustomerId = async (user, transformEmail, db, dbref, get) => {
    return await fetchFromFirebase(user, 'customer_id', transformEmail, db, dbref, get);
  };
  
  export const checkAllSet = async (user, transformEmail, db, dbref, get) => {
    return await fetchFromFirebase(user, 'all_set', transformEmail, db, dbref, get);
  };
  
  export const checkGmailLinked = async (user, transformEmail, db, dbref, get) => {
    return await fetchFromFirebase(user, 'gauth', transformEmail, db, dbref, get);
  };
  
  export const checkConfetti= async (user, transformEmail, db, dbref, get) => {
    return await fetchFromFirebase(user, 'confetti_shown', transformEmail, db, dbref, get);
  };
  
  export const chargeCustomer = async (amount, customerId, description) => {
    try {
      const response = await fetch("https://pay.emailwrkr.com/charge", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'YOUR_AUTHORIZATION_CODE' // Replace with your actual authorization code or environment variable
        },
        body: JSON.stringify({
          amount: amount,
          customerId: customerId,
          description: description
        })
      });
  
      const responseData = await response.json();
  
      if (responseData.success) {
        return true;
      } else {
        console.error("Failed to charge:", responseData.message);
        return false;
      }
    } catch (error) {
      console.error("Failed to charge:", error);
      return false;
    }
  };
  
  export const upgradeCustomer = async (customerId) => {
    try {
      const response = await fetch("https://pay.emailwrkr.com/upgrade", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'YOUR_AUTHORIZATION_CODE' // Replace with your actual authorization code or environment variable
        },
        body: JSON.stringify({
          customerId: customerId
        })
      });
  
      const responseData = await response.json();
  
      if (responseData.success) {
        return true;
      } else {
        console.error("Failed to upgrade:", responseData.message);
        return false;
      }
    } catch (error) {
      console.error("Failed to upgrade:", error);
      return false;
    }
  };
  