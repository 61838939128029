// firebaseUtils.js
import { initializeApp } from "firebase/app";
import { getDatabase, ref, get, set } from "firebase/database";

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIRE_API,
    authDomain: process.env.REACT_APP_FIRE_DOMAIN,
    databaseURL: process.env.REACT_APP_DB_URL,
    projectId: "emailwrkr",
    storageBucket: "emailwrkr.appspot.com",
    messagingSenderId: "681235122723",
    appId: process.env.REACT_APP_FIRE_ID
  };
  

const app = initializeApp(firebaseConfig);
const db = getDatabase(app);

export { db, ref as dbref, get, set };
