// render.js
export const renderInput = (setting, selectedOption, setSelectedOption, sliderValue, setSliderValue, priceSliderValue, setPriceSliderValue, priceSlider2Value, setPriceSlider2Value) => {
  switch (setting.type) {
    case "choice":
      return (
        <>
          {setting.subdescription && (
            <p style={{ fontStyle: 'italic', fontSize: '0.8rem', color: '#888', marginTop: '-30px' }}>
              {setting.subdescription}
            </p>
          )}
          {setting.options.map((option, index) => (
            <label key={index} className="optionLabel">
              <input
                type="radio"
                value={option}
                checked={selectedOption === option}
                onChange={() => setSelectedOption(option)}
                className="radio"
              />
              {option}
            </label>
          ))}
        </>
      );
    case "slider":
      return (
        <div>
          {setting.subdescription && (
            <p style={{ fontStyle: 'italic', fontSize: '0.8rem', color: '#888', marginTop: '-3.2rem' }}>
              {setting.subdescription}
            </p>
          )}
          <input 
            type="range" 
            min={setting.min || 1}  // Use the setting's min or default to 1
            max={setting.max || 10} // Use the setting's max or default to 10
            step={setting.step || 1} // Use the setting's step or default to 1
            value={sliderValue} 
            onChange={(e) => setSliderValue(e.target.value)}  
            className="slider"
          />
          <span>{sliderValue}</span>
        </div>
      );
    case "price-slider":
      return (
        <div>
          {setting.subdescription && (
            <p style={{ fontStyle: 'italic', fontSize: '0.8rem', color: '#888', marginTop: '-1rem' }}>
              {setting.subdescription}
            </p>
          )}
          <input 
            type="range" 
            min={setting.min || 0}  // Use the setting's min or default to 1
            max={setting.max || 100} // Use the setting's max or default to 100 for price
            step={setting.step || 1} // Use the setting's step or default to 1
            value={priceSliderValue} 
            onChange={(e) => setPriceSliderValue(parseInt(e.target.value, 10))} 
            className="slider"
          />
          <span>{priceSliderValue === 0 ? 200 : priceSliderValue}</span>
          <p style={{ marginTop: '10px' }}>Your Price: ${priceSliderValue === 0 ? 0 : (priceSliderValue * .001).toFixed(2)}</p>
          <p style={{ fontSize: '0.8rem', color: '#888', marginTop: '-1rem' }}>
              (By saving, you authorize a charge of this amount)
          </p>
        </div>
      );
    case "price-slider-2":
      return (
        <div>
          {setting.subdescription && (
            <p style={{ fontStyle: 'italic', fontSize: '0.8rem', color: '#888', marginTop: '-2.4rem' }}>
              {setting.subdescription}
            </p>
          )}
          <input 
            type="range" 
            min={setting.min || 0}  // Use the setting's min or default to 1
            max={setting.max || 100} // Use the setting's max or default to 100 for price
            step={setting.step || 1} // Use the setting's step or default to 1
            value={priceSlider2Value} 
            onChange={(e) => setPriceSlider2Value(parseInt(e.target.value, 10))} 
            className="slider"
          />
          <span>{priceSlider2Value === 0 ? 0 : priceSlider2Value}</span>
          <p style={{ marginTop: '10px' }}>Your Price: ${priceSlider2Value === 0 ? 0 : (priceSlider2Value * .001).toFixed(2)}</p>
          <p style={{ fontSize: '0.8rem', color: '#888', marginTop: '-1rem' }}>
              (By saving, you authorize a charge of this amount)
          </p>
        </div>
      );
    default:
      return null;
  }
};
