// saveSettings.js
export const saveSetting = async (currentSlide, settingsList, selectedOption, sliderValue, priceSliderValue, priceSlider2Value, user, transformEmail, chargeCustomer, set, db, dbref, get, setCurrentSlide, setSelectedOption, setIsLoading, upgradeCustomer) => {
    const setting = settingsList[currentSlide];
    let dataToSave;
    setIsLoading(true);
    
    let customerId;
    let user_email = transformEmail(user.email);

    // Get customer_id from RTDB
    const userRef = dbref(db, `gmail_creds/${user_email}`);
    const snapshot = await get(userRef);
    if (snapshot.exists()) {
      customerId = snapshot.val().customer_id;
    } else {
      alert("User not found in database. Please check your email and try again.");
      return;
    }
    switch (setting.type) {
      case "choice":
        if (setting.name === "speed") {
          dataToSave = selectedOption === "Standard: Every 3 minutes" ? 0 : 1;
        } else if (setting.name === "auto_filter_on") {
          dataToSave = selectedOption === "On" ? 1 : 0;
        } else if (setting.name === "auto_send") { 
          dataToSave = selectedOption === "Draft" ? 0 : 1;
        }
        break;

      case "slider":
        dataToSave = sliderValue;
        break;
      case "price-slider":
        dataToSave = priceSliderValue;

        if (setting.name === "auto_filter_go_back" && dataToSave === 0) {
            dataToSave = 200;
        } else {
            const chargeSuccessful = await chargeCustomer(dataToSave * 0.001, customerId, `Inbox Clean Up: ${dataToSave} emails`);
            if (!chargeSuccessful) {
                alert("Failed to charge. Please try again.");
                return;
            }
        }
        break;

      case "price-slider-2":
        dataToSave = priceSlider2Value;

        if (setting.name === "personal_ai" && dataToSave === 0) {
            dataToSave = 1000;
        } else {
            const chargeSuccessful = await chargeCustomer(dataToSave * 0.001, customerId, `AI Personalization: ${dataToSave} extra replies training`);
            if (!chargeSuccessful) {
                alert("Failed to charge. Please try again.");
                return;
            }
        }
        break;

      default:
        alert("Please make a selection before saving.");
        return;
    }
  
    
    if (setting.name === 'speed') {
      let data = (dataToSave === 0) ? 180 : 30;
      if (dataToSave === 1) {
        upgradeCustomer(customerId)
      }
      try {
          const response = await fetch("https://work.emailwrkr.com/start", {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
                  'Authorization': 'your_authorization_code'
              },
              body: JSON.stringify({
                  email: user.email,
                  speed: data
              })
          });
      
          const responseData = await response.json();
      
          if (responseData.success) { // Assuming the response has a 'success' field
              console.log("POST request to start the personal AI was successful:", responseData.data);
              // Save 'all_set' to Firebase - settings complete tag
              set(dbref(db, `gmail_creds/${user_email}/${'all_set'}`), 1);
              // Save to Firebase
              set(dbref(db, `gmail_creds/${user_email}/${setting.name}`), data);
              setIsLoading(false);
              alert("All settings saved!");
          } else {
              console.error("Failed to send POST request:", responseData.message);
          }
      } catch (error) {
          console.error("Failed to send POST request:", error);
      }    
    } else { // not setting 'speed'
      // Save to Firebase
      set(dbref(db, `gmail_creds/${user_email}/${setting.name}`), dataToSave);
      if (currentSlide < settingsList.length - 1) {
      setCurrentSlide(currentSlide + 1);
      setSelectedOption(null); // Reset for next slide
    } 
    setIsLoading(false);
    }
    
  };
  