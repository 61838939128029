const settingsList = [
    {
      title: "Auto-Filter",
      description: "Auto-Filtering uses the labels and categories you have used in the past, to organize emails now and in the future.",
      subdescription: "It is always learning, and if you add new categories or labels in the future, it will automatically start to use those",
      type: "choice",
      options: ["On", "Off"],
      name: "auto_filter_on",
      defaultValue: "On"
    },
    {
      title: "Auto-Filter Level",
      description: "Choose your filtering level from 1 to 10. Higher equals more organized.",
      subdescription: "The lowest setting, 1, will make Email Wrkr rarely categorize or label any of your email. 7 is the default",
      type: "slider",
      name: "auto_filter_level",
      defaultValue: 7
    },
    {
      title: "Auto-Filter Start",
      description: "How much would you like us to clean up your inbox?",
      subdescription: "By default, we will organize the last 200 emails free-of-charge, but most clients want us to go back through their entire inbox. How many unread emails do you have?",
      type: "price-slider",
      name: "auto_filter_go_back",
      min: 0, 
      max: 100000,
      step: 10000,
      defaultValue: 0
    },
    {
      title: "Auto-Reply: Send or Draft",
      description: "Auto-Reply uses your email replies to train a custom model of ChatGPT just for you.",
      subdescription: "ChatGPT is trained to sound like you and know what you respond to. If response is likely, your personal AI creates a draft reply for you. Should we go ahead and send the replies we create, or leave them as a draft?",
      type: "choice",
      options: ["Draft", "Auto-Send"],
      name: "auto_send",
      defaultValue: "Draft"
    },
    {
      title: "Auto-Reply Level",
      description: "How often would you like your AI to draft replies?",
      subdescription: "The lowest setting will never create a reply. Level 10 will always create a reply. Level 9 will ask your AI whether or not to reply, and reply when appropriate",
      type: "slider",
      name: "auto_reply_level",
      defaultValue: 7
    },
    {
      title: "Personalized AI",
      description: "",
      subdescription: "When you start a free trial, we train a custom model of ChatGPT just for you! Training is the most expensive part of AI, so we can only afford to train on 1000 messages for each user initially. However, your personalized model would perform better and sound more like you with additional training, which we highly recommend. How much additional training would you like for your personal AI?",
      type: "price-slider-2",
      name: "personal_ai",
      min: 0, 
      max: 100000,
      step: 10000,
      defaultValue: 0
    },
    {
      title: "Update Cycle",
      description: "How often would you like your AI working?",
      subdescription: "Your Email Wrkr AI automatically checks your inbox and handles new emails every 3 minutes. If you would like it to check your email every 30 seconds, upgrade to the Premium package.",
      type: "choice",
      name: "speed",
      options: ["Standard: Every 3 minutes", "Premium: Every 30 seconds"],
      defaultValue: "Standard: Every 3 minutes"
    },
    // ... add other settings here
  ];
  
  export default settingsList;
  