
// TODO: After final setting is complete, set the settings_set state to true, rendering a dashboard and show a burst of confetti on the page!
// App.js
import React, { useCallback, useState, useEffect } from 'react';

// Styles
import './App.css';
import { styles } from './styles';

// Components
import ParticlesBackground from './ParticlesBackground';
import Dashboard from './Dashboard';

// Utilities
// import useMediaQuery from './useMediaQuery';
import settingsList from './settingsList';
import { transformEmail } from './utils';
import { renderInput } from './render';

// Firebase & Auth
import { GoogleAuthProvider, signInWithPopup, signOut } from 'firebase/auth'
import { 
  redirectToStripe, 
  sendToSettings, 
  getAuth, 
  onAuthStateChanged 
} from './auth';

// Stripe
import { 
  checkStripeCustomerId, 
  chargeCustomer, 
  checkAllSet, 
  upgradeCustomer, 
  checkGmailLinked 
} from './stripe';

// Firebase Utils
import { db, dbref, get, set } from './firebaseUtils';
import { saveSetting } from './saveSettings';

const UserInfo = ({ user, handleLogout }) => (
  <div className="user-info">
    <img src={user.photoURL} alt="User's profile" className="user-image" />
    <span className="user-email">{user.email}</span>
    <button 
      onClick={() => handleLogout()}
      className="text-button" 
      style={{fontSize: "1rem", position: 'absolute', top: '50px'}}
    >
      Logout
    </button>
  </div>
);

const LoadingSpinner = ({ isLoading }) => (
  <div className="loading-spinner" id="loading-spinner" style={{ ...styles.loadingSpinner, display: isLoading ? 'block' : 'none', marginTop: '-20px' }}>
    <svg viewBox="0 0 50 50" style={styles.loadingSpinnerSvg}>
      <circle cx="25" cy="25" r="20" stroke="#007aff" strokeWidth="5" fill="none" style={styles.loadingSpinnerCircle} />
    </svg>
  </div>
);

function App() {
  const [appState, setAppState] = useState({
    user: null,
    hasStripe: null,
    allSet: null,
    gmailLinked: null,
  });
  const [currentSlide, setCurrentSlide] = useState(0);
  const [selectedOption, setSelectedOption] = useState(settingsList[currentSlide].defaultValue);
  const [sliderValue, setSliderValue] = useState(settingsList[currentSlide].defaultValue);
  const [priceSliderValue, setPriceSliderValue] = useState(0);
  const [priceSlider2Value, setPriceSlider2Value] = useState(0);
  const [isOnboarding, setIsOnboarding] = useState(window.location.search.includes('onboarding'));
  const [isLoading, setIsLoading] = useState(false);
  
  const handleGoogleLogin = useCallback(async () => {
    try {
      const auth = getAuth();
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, provider);
      if (result && result.user && !appState.user) {
          setAppState(prevState => ({
              ...prevState,
              user: result.user,
          }));
          // save to local cache
          localStorage.setItem('user', JSON.stringify(result.user));
      }
    } catch (err) {
        console.error('Error with popup:', err);
        alert('Login failed. Please try again.');
    }
  }, [appState.user]);
  
  const handleLogout = useCallback(() => {
      const auth = getAuth();
      signOut(auth).then(() => {
          setAppState(prevState => ({ ...prevState, user: null }));
      });
      // Clear user data from local storage
      localStorage.removeItem('user');
      localStorage.removeItem('hasStripe');
      localStorage.removeItem('gmailLinked');
  }, []);

  useEffect(() => {
      const authInstance = getAuth();

      // Check local storage first
      const localUser = JSON.parse(localStorage.getItem('user'));
      const localHasStripe = localStorage.getItem('hasStripe');
      const localGmailLinked = localStorage.getItem('gmailLinked');
      const localAllSet = localStorage.getItem('allSet');

      if (localUser && !appState.user) {
          let updatedState = {
              ...appState,
              user: localUser
          };

          if (localHasStripe !== null) {
              updatedState.hasStripe = localHasStripe;
          }

          if (localGmailLinked !== null) {
              updatedState.gmailLinked = localGmailLinked;
          }

          if (localAllSet !== null) {
              updatedState.allSet = localAllSet;
          }

          setAppState(prevState => ({
              ...prevState,
              ...updatedState
          }));
      } else {
          const unsubscribe = onAuthStateChanged(authInstance, async (currentUser) => {
              if (currentUser && !appState.user) {
                  const stripeStatus = await checkStripeCustomerId(currentUser, transformEmail, db, dbref, get);
                  const gmailLinkedStatus = await checkGmailLinked(currentUser, transformEmail, db, dbref, get);
                  const allSetStatus = await checkAllSet(currentUser, transformEmail, db, dbref, get);
                  
                  setAppState(prevState => ({
                      ...prevState,
                      user: currentUser,
                      hasStripe: stripeStatus,
                      gmailLinked: gmailLinkedStatus,
                      allSet: allSetStatus
                  }));

                  // Save to local storage
                  localStorage.setItem('user', JSON.stringify(currentUser));
                  localStorage.setItem('hasStripe', stripeStatus);
                  localStorage.setItem('gmailLinked', gmailLinkedStatus);
                  localStorage.setItem('allSet', allSetStatus);
              }
          });

          return () => unsubscribe();
      }
  }, [appState]);

  useEffect(() => {
      setSelectedOption(settingsList[currentSlide].defaultValue);
      if (settingsList[currentSlide].type === "slider") {
          setSliderValue(settingsList[currentSlide].defaultValue);
      }
  }, [currentSlide]);


  if (isOnboarding) {
      if (appState.gmailLinked) {
          window.location.href = "https://app.emailwrkr.com";
          setIsOnboarding(false);
          return null; // or a loading component, as the user will be redirected.
      } else {
          return <OnboardingPage user={appState.user} handleLogout={handleLogout} />;
      }
  } else if (!appState.user) {
      return <LoginPage handleGoogleLogin={handleGoogleLogin} />;
  } else if (!appState.hasStripe) {
      return <SignUpPage user={appState.user} handleLogout={handleLogout} />;
  } else if (!appState.allSet) {
      return (
          <SettingsPage
              currentSlide={currentSlide}
              user={appState.user}
              isLoading={isLoading}
              settingsList={settingsList}
              renderInput={renderInput}
              selectedOption={selectedOption}
              setSelectedOption={setSelectedOption}
              sliderValue={sliderValue}
              setSliderValue={setSliderValue}
              priceSliderValue={priceSliderValue}
              setPriceSliderValue={setPriceSliderValue}
              priceSlider2Value={priceSlider2Value}
              setPriceSlider2Value={setPriceSlider2Value}
              saveSetting={saveSetting}
              transformEmail={transformEmail}
              chargeCustomer={chargeCustomer}
              set={set}
              db={db}
              dbref={dbref}
              get={get}
              setCurrentSlide={setCurrentSlide}
              setIsLoading={setIsLoading}
              upgradeCustomer={upgradeCustomer}
              handleLogout={handleLogout}
          />
      );
  } else {
      return <DashboardPage user={appState.user} isLoading={isLoading} handleLogout={handleLogout} />;
  }
}
  
  const LoginPage = ({ handleGoogleLogin }) => (
    <div className="container">
      <ParticlesBackground />
      <div className="slide">
        <h2 style={{ marginTop: '1rem' }}>Login Required</h2>
        <p style={{ marginTop: '-1rem' }}>Sign in with your Gmail account to access your settings.</p>
        <button onClick={handleGoogleLogin} className="button" style={{fontSize: "1rem", marginBottom: '10rem' }}>
          Sign in with Google
        </button>
      </div>
    </div>
  );

  const OnboardingPage = ({ user, handleLogout }) => (
    <div className="container">
      <ParticlesBackground />
      <div className="slide">
        <div className="centered-container">
          <img src="/jumping-for-joy.jpg" width="200" height="200" alt="Jumping for Joy" />
        </div>
        <h2 style={{ marginTop: '1rem' }}>Congrats!</h2>
        <p style={{ marginTop: '-.3rem' }}>Your account is confirmed! Authorize on Gmail so your AI can get started!</p>
        <button onClick={sendToSettings} className="button" style={{ marginBottom: '1rem' }}>Authorize on Gmail</button>
      </div>
      <UserInfo user={user} handleLogout={handleLogout} />
    </div>
  );
  
  const SignUpPage = ({ user, handleLogout }) => (
    <div className="container">
      <ParticlesBackground />
      <div className="slide">
        <h2 style={{ marginTop: '1rem' }}>Email Wrkr Account Required</h2>
        <p style={{ marginTop: '-1rem' }}>Please start your free trial to Email Wrkr to proceed.</p>
        <button onClick={redirectToStripe} className="button" style={{ fontSize: "1rem", marginBottom: '10rem' }}>
          Sign up to Free Trial
        </button>
      </div>
      <UserInfo user={user} handleLogout={handleLogout} />
    </div>
  );
  
 
  const SettingsPage = ({ 
      currentSlide, 
      user, 
      isLoading, 
      settingsList,
      renderInput,
      selectedOption, 
      setSelectedOption, 
      sliderValue, 
      setSliderValue, 
      priceSliderValue, 
      setPriceSliderValue, 
      priceSlider2Value, 
      setPriceSlider2Value, 
      saveSetting,
      transformEmail,
      chargeCustomer,
      set,
      db,
      dbref,
      get,
      setCurrentSlide, 
      setIsLoading, 
      upgradeCustomer,
      handleLogout, 
      ...otherProps 
  }) => (
    <div className="container">
      <ParticlesBackground />
      <div className="slide">
        <h2>{settingsList[currentSlide].title}</h2>
        <p>{settingsList[currentSlide].description}</p>
        {renderInput(settingsList[currentSlide], selectedOption, setSelectedOption, sliderValue, setSliderValue, priceSliderValue, setPriceSliderValue, priceSlider2Value, setPriceSlider2Value)}
        <LoadingSpinner isLoading={isLoading} />
        <button onClick={() => saveSetting(currentSlide, settingsList, selectedOption, sliderValue, priceSliderValue, priceSlider2Value, user, transformEmail, chargeCustomer, set, db, dbref, get, setCurrentSlide, setSelectedOption, setIsLoading, upgradeCustomer)} className="button" style={{ fontSize: "1rem" }}>
          Save
        </button>
      </div> 
      <UserInfo user={user} handleLogout={handleLogout} />
    </div>
  );
  
  const DashboardPage = ({ user, isLoading, handleLogout }) => (
    <div className="container">
      <ParticlesBackground />
      <div className="slide">
        <Dashboard user={user} />
        <LoadingSpinner isLoading={isLoading} />
      </div>
      <UserInfo user={user} handleLogout={handleLogout} />
    </div>
  );

export default App;