import React, { useState, useEffect } from 'react';
import { ResponsivePie } from '@nivo/pie';
import { ResponsiveLine } from '@nivo/line';
import confetti from 'canvas-confetti';
import { transformEmail } from './utils';
import { db, get, dbref, set } from './firebaseUtils';

// const savageColors = [
//     {grey: 5E6572, 71697A, },
//     {pink: FCBCB8, },
//     {purple: BDA0BC, },
//     {yellow: F2BB05, F9DF74, },
//     {blue: 124E78, },
//     {bright green: 70F8BA, },
// ]



const dailyData = [
    { day: 'Monday, (06/13/23)', processed: 50, archived: 20, labeled: 2, replies: 2, stars: 0 },
    { day: 'Tuesday, (06/14/23)', processed: 55, archived: 25, labeled: 1, replies: 3, stars: 0 },
    { day: 'Wednesday, (06/15/23)', processed: 40, archived: 19, labeled: 0, replies: 2, stars: 1 },
    { day: 'Thursday, (06/16/23)', processed: 50, archived: 28, labeled: 0, replies: 2, stars: 2 },
    { day: 'Friday, (06/17/23)', processed: 30, archived: 18, labeled: 1, replies: 0, stars: 0 },
    { day: 'Saturday, (06/18/23)', processed: 20, archived: 10, labeled: 0, replies: 1, stars: 2 },
    { day: 'Sunday, (06/19/23)', processed: 50, archived: 35, labeled: 1, replies: 3, stars: 1 }
];

const pieData = [
    { id: "Archived", label: "Archived", value: dailyData.reduce((sum, day) => sum + day.archived, 0) },
    { id: "Labeled", label: "Labeled", value: dailyData.reduce((sum, day) => sum + day.labeled, 0) },
    { id: "Replies", label: "Replied", value: dailyData.reduce((sum, day) => sum + day.labeled, 0) },
    { id: "Stars", label: "Starred", value: dailyData.reduce((sum, day) => sum + day.stars, 0) }
    ];

const lineDataProcessedArchived = [
    {
        id: 'Processed',
        data: dailyData.map(d => ({ x: d.day, y: d.processed }))
    },
    {
        id: 'Archived',
        data: dailyData.map(d => ({ x: d.day, y: d.archived }))
    }
];

const lineDataLabeledReplies = [
    {
        id: 'Replied',
        data: dailyData.map(d => ({ x: d.day, y: d.replies }))
    },
    {
        id: 'Labeled',
        data: dailyData.map(d => ({ x: d.day, y: d.labeled }))
    },
    {
        id: 'Starred',
        data: dailyData.map(d => ({ x: d.day, y: d.stars }))
    }
];

const PieTooltip = ({ datum }) => {
    return (
        <div style={{ background: 'white', padding: '5px', border: '1px solid #ddd' }}>
            <strong>{datum.id}</strong>
            <div>{datum.value} emails</div>
        </div>
    );
};

const CustomTooltip = ({ point }) => {
    return (
        <div 
            style={{
                background: 'white',
                padding: '10px',
                borderRadius: '2px',
                boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)'
            }}
        >
            <div><strong>{point.serieId}</strong></div>
            <div>{`${point.data.x}`}</div>
            <div>{`${point.data.y}`}</div>
        </div>
    );
};

// Fetch the value of confetti_shown from Firebase RTDB
const fetchConfettiStatus = async (user) => {
    const userEmail = transformEmail(user.email);
    const userRef = dbref(db, `gmail_creds/${userEmail}/confetti_shown`);
    const snapshot = await get(userRef);
    if (snapshot.exists()) {
        return (snapshot.val())
    } else {
        return false 
    }
};
// Fetch the value of confetti_shown from Firebase RTDB
const setConfettiStatus = async (user) => {
    const userEmail = transformEmail(user.email);
    set(dbref(db, `gmail_creds/${userEmail}/confetti_shown`), 1);
    }


const Dashboard = ({ user }) => {
    const [showConfetti, setShowConfetti] = useState(false);

    useEffect(() => {
        const fetchStatus = async () => {
            const status = await fetchConfettiStatus(user);
            setShowConfetti(!status);
        };
        fetchStatus();
    }, [user]);

    useEffect(() => {
        if (showConfetti) {
            const confettiAnimation = confetti.create(null, { resize: true });
            confettiAnimation({
                particleCount: 100,
                spread: 70,
                origin: { y: 0.8 } // make it burst from the bottom
            });
            setConfettiStatus(user); // Update the Firebase database
        }
    }, [showConfetti, user]);
    

    return (
        <div style={{ maxWidth: '100wv', margin: 'auto', paddingBottom: '20px' }}>
            
            {/* Emails Processed & Archived - Line Chart */}
            <div style={{ height: '100px', marginTop: '20px' }}>
                <h3>Processed / Archived Emails</h3>
                <ResponsiveLine 
                    data={lineDataProcessedArchived}
                    colors={['#A2D5F2', '#2C3E50', '#FF9F5B', '#30AAB5', '#A381FF']}
                    margin={{ top: 10, right: 20, bottom: 10, left: 10 }}
                    pointSize={10}
                    useMesh={true}
                    axisLeft={null} 
                    axisBottom={null} 
                    tooltip={CustomTooltip}
                    enableGridX={false}
                    enableGridY={false}
                />
            </div>

            {/* Emails Labeled & Replies - Line Chart */}
            <div style={{ height: '100px', marginTop: '50px' }}>
                <h3>Labeled / Replied Emails</h3>
                <ResponsiveLine 
                    data={lineDataLabeledReplies}
                    colors={['#FF6F59', '#124E78', '#FEE440', '#A381FF']}
                    margin={{ top: 10, right: 20, bottom: 10, left: 10 }}
                    pointSize={10}
                    useMesh={true}
                    axisLeft={null} 
                    axisBottom={null} 
                    tooltip={CustomTooltip}
                    enableGridX={false}
                    enableGridY={false}
                />
            </div>

             {/* Emails Processed, Archived, and Labeled - Pie Chart */}
             <div style={{ height: '200px', marginTop: '70px' }}>
                <h3>Emails Processed</h3>
                <ResponsivePie 
                    data={pieData}
                    margin={{ top: 10, right: 10, bottom: 10, left: 10 }}
                    colors={['#A2D5F2', '#1D3557', '#E63946', '#E2F1AF', '#E3D888']}
                    innerRadius={0.5}
                    padAngle={0.7}
                    cornerRadius={3}
                    activeOuterRadiusOffset={8}
                    borderWidth={1}
                    enableArcLinkLabels={false}
                    sliceTooltip={PieTooltip} // Use custom tooltip for pie slices
                    // ...rest of your props...
                />
            </div>
        </div>
    );
}

export default Dashboard;