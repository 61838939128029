export const transformEmail = (email) => {
    return email
      .toLowerCase()
      .replace(/\./g, '_')
      .replace(/@/g, '_')
      .replace(/\$/g, '_')
      .replace(/\[/g, '_')
      .replace(/\]/g, '_')
      .replace(/#/g, '_')
      .replace(/\//g, '_');
  };
  