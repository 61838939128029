export const styles = {
    loadingSpinner: {
        display: "none",
        marginTop: "16px",
        textAlign: "center",
    },
    loadingSpinnerSvg: {
        width: "30px",
        height: "30px",
    },
    loadingSpinnerCircle: {
        strokeDasharray: "113",
        strokeDashoffset: "113",
        transformOrigin: "center",
        animation: "spin 1s linear infinite, draw 1s linear infinite",
    }
}