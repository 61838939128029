import { getAuth, getRedirectResult, onAuthStateChanged } from 'firebase/auth';

export const redirectToStripe = () => {
  window.location.href = "https://buy.stripe.com/3cs3fM3ZzaFT1wY148";
};

export const sendToSettings = () => {
  window.location.href = "https://auth.emailwrkr.com/authorize";
};

export { getAuth, getRedirectResult, onAuthStateChanged };